import { Flex, Button, HStack, Icon, useToast } from '@chakra-ui/react';
import { MdMenu } from 'react-icons/md';
import UserProfile from '@components/UserProfile';
import { useRouter } from 'next/router';
import { motion } from 'framer-motion';
import { useSession } from 'next-auth/react';
import { useEffect, useState } from 'react';
import CustomNextImage from '@components/ChakraNextImage';

export default function Header({
  onOpen,
  ...rest
}: {
  onOpen: any;
  rest?: any;
}) {
  const { data: _session, status } = useSession();
  const [isOnTop, setIsOnTop] = useState(false);
  const router = useRouter();
  const toast = useToast();

  const handleScroll = () => {
    const scrollPosition = window.scrollY; // => scroll position
    setIsOnTop(scrollPosition == 0);
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <Flex
      position="sticky"
      top="0"
      pl={[5, 10, 15]}
      pr={[5, 10, 15, 20]}
      height={status === 'unauthenticated' ? '80px' : '90px'}
      zIndex="2"
      alignItems="center"
      bg={isOnTop ? undefined : 'lpHeaderScrollColor'}
      backdropFilter={isOnTop ? undefined : 'saturate(180%) blur(5px)'}
      opacity={0.95}
      justifyContent="space-between"
      {...rest}
    >
      <HStack zIndex={2} spacing={6}>
        <Icon
          display={{ base: 'flex', md: 'none' }}
          onClick={onOpen}
          color="white"
          w={6}
          h={6}
          size="lg"
          aria-label="open menu"
          as={MdMenu}
          zIndex={2}
        />
        <CustomNextImage
          src={'/imgs/pizza_hut/logohut.png'}
          alt={`Da Um hut`}
          width={'58px'}
          height={'52px'}
        />
        {/* {status === 'unauthenticated' && ( */}
        <HStack spacing={9} pr={5}>
          <Button
            py={0}
            variant="lpHeaderButton"
            size="lg"
            onClick={() => {
              if (status === 'unauthenticated') {
                router.push('/login');
              } else {
                toast({
                  title: 'Participação encerrada! ',
                  description: `Período da promoção de 19/12/2023 até 29/01/2024.`,
                  status: 'warning',
                  duration: 9000,
                  isClosable: true,
                  position: 'top',
                });
              }
            }}
            display={{ base: 'none', md: 'flex' }}
            as={motion.button}
            dragConstraints={{ left: -100, right: 100 }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            transition="0.05s linear"
          >
            {status === 'unauthenticated'
              ? 'QUERO PARTICIPAR'
              : 'CADASTRAR NOTA'}
          </Button>

          <Button
            variant="lpHeaderButton"
            size="lg"
            onClick={() => {
              router.push('/regulations');
            }}
            display={{ base: 'none', md: 'flex' }}
            as={motion.button}
            dragConstraints={{ left: -100, right: 100 }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            transition="0.05s linear"
          >
            REGULAMENTO
          </Button>
        </HStack>
        {/* )} */}
        {/* 
        <Link
          onClick={() => {
            router.push('/faq');
          }}
          variant="lpHeaderLinks"
        >
          Dúvidas?
        </Link> */}
      </HStack>
      <UserProfile />
    </Flex>
  );
}
