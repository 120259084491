import {
  Container,
  Box,
  VStack,
  Text,
  Link,
  Stack,
  // StackDivider,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';

export default function Footer({ minH }: { minH?: string }) {
  const router = useRouter();
  const { t } = useTranslation('landing-page');
  return (
    <Box
      py={[5, 6, 9, 12]}
      bg={'lpFooterColor'}
      as={motion.div}
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition="1s linear"
      viewport={{ once: true }}
      position={'relative'}
      // zIndex={'overlay'}
      bottom={0}
      minH={minH}
      w={'full'}
    >
      <Container zIndex={0} maxW={{ base: 'full', md: '5xl', xl: '1690px' }}>
        <VStack zIndex={0} spacing={5}>
          <Stack
            direction={['column', 'column', 'row']}
            spacing="10px"
            align="center"
          >
            <Link
              variant="lpFooterLinks"
              onClick={() => {
                router.push('/faq');
              }}
            >
              {t('banner-footer-duvidas')}
            </Link>
            {/* <StackDivider
              display={{ base: 'none', md: 'flex' }}
              alignSelf={'center'}
              w="4px"
              h="26px"
              bgColor="white"
            /> */}
            <Text
              display={{ base: 'none', md: 'flex' }}
              fontSize={'28px'}
              color={'white'}
            >
              /
            </Text>
            <Link
              variant="lpFooterLinks"
              onClick={() => {
                router.push('/terms');
              }}
            >
              {t('banner-footer-politica')}
            </Link>
          </Stack>
          <Text variant="lpFooterText">
            <Trans
              i18nKey="landing-page:banner-footer-description"
              components={[<br />]}
            />
          </Text>
          <Link href="https://starmkt.com.br/" target="_blank" color={'white'}>
            Desenvolvido por StarMKT
          </Link>
        </VStack>
      </Container>
    </Box>
  );
}
